<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="标题：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item label="分类：" >
<!--            <el-select v-model="listQuery.category" class="input-width" placeholder="全部" clearable>
              <el-option v-for="item in orderStatusOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>-->
            <el-cascader

                clearable  filterable
                :props="{ checkStrictly: true }"
                v-model="queryProductCateValue"
                :options="productCateOptions">
            </el-cascader>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" >新增</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="fileTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="分类"  align="center">
          <template #default="scope">{{scope.row.categoryName}}</template>
        </el-table-column>
        <el-table-column label="标题"  align="center">
          <template #default="scope">{{scope.row.title}}</template>
        </el-table-column>
        <el-table-column label="文件名称"  align="center">
          <template #default="scope">{{scope.row.file_name}}</template>
        </el-table-column>
        <el-table-column label="文件类型"  align="center">
          <template #default="scope">{{scope.row.file_type}}</template>
        </el-table-column>
        <el-table-column label="发布时间" align="center">
          <template #default="scope">{{scope.row.release_time}}</template>
        </el-table-column>
        <el-table-column label="缩略图" align="center">
          <template #default="scope"><img style="height: 80px" :src="scope.row.pic" @click="handlePictureEnlarge(scope.row.pic)"></template>
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" @click="handleUpdate(scope.row)" >设置类别</el-button>
            <el-button size="mini" type="primary" @click="handleShowPic(scope.row)" >设置缩略图</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog v-model="dialogVisiblePic" title="图片">
      <img :src="enlargedImageUrl" alt="Enlarged Image" style="max-width: 100%; display: block; margin: auto;">
    </el-dialog>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="fileModel"
               ref="fileFrom"
               label-width="150px"
               size="small">
        <el-form-item label="分类：" v-if="this.isEditPicFlag == false">
          <el-cascader
              clearable  filterable
              :props="{ checkStrictly: true }"
              v-model="selectProductCateValue"
              :options="productCateOptions">
          </el-cascader>
        </el-form-item>

        <el-form-item label="文件：" v-if="this.isEditFlag == false">
          <el-upload ref="uploadForm" v-model="fileModel.attachment" drag multiple
                     action="#"
                     :file-list="fileListShow"
                     :auto-upload="false"
                     :before-remove="handleRemove"
                     :on-change="fileChange"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>

        <el-form-item label="缩略图：" v-if="this.isEditFlag == true && this.isEditPicFlag == true">
          <el-upload ref="uploadPicForm" v-model="fileModel.pic" drag
                     accept="image/*"
                     action="#"
                     :limit="1"
                     :file-list="fileListShowPic"
                     :auto-upload="false"
                     :before-remove="handleRemovePic"
                     :on-change="fileChangePic"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('fileFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>

import {
  batchDeleteFile,
  deleteFile,
  fetchList,
  uploadFile,
  deleteUploadFile,
  deleteBatchUploadFile, updateCategory, updateShowPic, deleteBatchUploadPicFile, deleteUploadPicFile
} from "@/api/file";
import {fetchAllCateWithChildren } from "@/api/category";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
  category: null,
};

const defaultFile = {
  title: null,
  category: null,
  attachment: null,
  pic: null,
  original_name: null,
  file_name: null,
  file_type: null,
  comment: null,
  release_time: null,
};

export default {
  name: 'File',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      orderStatusOptions: [],
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      attachments: [],
      dialogVisible: false,
      dialogVisiblePic: false,
      enlargedImageUrl: '',
      isEditFlag: false,
      isEditPicFlag: false,
      fileModel: Object.assign({}, defaultFile),
      fileListShow:[],
      fileListShowPic:[],
      fileList:[],
      fileListPic:[],
      selectProductCateValue: null,
      queryProductCateValue: null,
      productCateOptions: [],
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    handlePictureEnlarge(imageUrl) {
      this.enlargedImageUrl = imageUrl;
      this.dialogVisiblePic = true;
    },
    //文件上传
    fileChange(file, fileList) {
      this.fileList.push(file);
      this.fileListShow.push({
        name: file["name"],
      })

    },
    //文件上传
    fileChangePic(file, fileList) {

      this.fileListPic.push(file);
      this.fileListShowPic.push({
        name: file["name"],
      })

    },
    //文件删除
    handleRemove(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileList = this.fileList.filter(item => item.name !== file.name);
    },
    //文件删除
    handleRemovePic(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileListPic = this.fileListPic.filter(item => item.name !== file.name);
    },
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.queryProductCateValue = null;
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
      this.attachments = val.map(v => v.attachment);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": row.id,
        };
        deleteUploadFile(data);
        deleteUploadPicFile(data);

        deleteFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
        };

        //删除附件
        deleteBatchUploadFile(data);
        deleteBatchUploadPicFile(data);

        batchDeleteFile(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;

      if(this.queryProductCateValue != null){
        if (this.queryProductCateValue.length === 3) {
          this.listQuery.category = this.queryProductCateValue[2];
        }else if(this.queryProductCateValue.length === 2){
          this.listQuery.category = this.queryProductCateValue[1];
        }else{
          this.listQuery.category = this.queryProductCateValue[0];
        }
      }else{
        this.listQuery.category = this.queryProductCateValue;
      }

      fetchList(this.listQuery).then(res => {
        if (res.code === '0') {
          this.listLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }

      })

      fetchAllCateWithChildren().then(res => {
        //this.orderStatusOptions = res.data
        let list = res.data;
        this.productCateOptions = [];
        for (let i = 0; i < list.length; i++) {
          let children = [];
          if (list[i].children != null && list[i].children.length > 0) {
            for (let j = 0; j < list[i].children.length; j++) {
              children.push({label: list[i].children[j].name, value: list[i].children[j].id});
            }
          }
          this.productCateOptions.push({label: list[i].name, value: list[i].id, children: children});
        }
      })

    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.isEditPicFlag = false;
      this.fileModel = Object.assign({},defaultFile);
      if(this.$refs["uploadForm"]){
        this.$refs['uploadForm'].clearFiles()  // 清除历史文件列表
      }
      this.fileList = [];
      this.fileListShow = [];
      this.selectProductCateValue = null;
    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.isEditPicFlag = false;
      this.fileModel = Object.assign({},row);
      if(row.category == 0){
        this.fileModel.category = null;
      }
      this.selectProductCateValue = row.category;
    },
    //修改路由
    handleShowPic(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.isEditPicFlag = true;
      this.fileModel = Object.assign({},row);
      if(row.category == 0){
        this.fileModel.category = null;
      }
      this.selectProductCateValue = row.category;

      if(this.$refs["uploadForm"]){
        this.$refs['uploadForm'].clearFiles()  // 清除历史文件列表
      }
      this.fileListPic = [];
      this.fileListShowPic = [];
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (!this.isEditFlag) {
            if (this.fileList.length === 0){
              this.$message.warning('请上传文件');
              return;
            }
          }

          if (this.isEditFlag && this.isEditPicFlag) {
            if (this.fileListPic.length === 0){
              this.$message.warning('请上传缩略图');
              return;
            }
          }

          if(this.selectProductCateValue != null){
            if (this.selectProductCateValue.length === 3) {
              this.fileModel.category = this.selectProductCateValue[2];
            }else if(this.selectProductCateValue.length === 2){
              this.fileModel.category = this.selectProductCateValue[1];
            }else{
              this.fileModel.category = this.selectProductCateValue[0];
            }
          }else{
            this.fileModel.category = 0;
          }


          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {

              if(this.isEditPicFlag){

                const form = new FormData();
                for (let i = 0; i < this.fileListPic.length; i++) {
                  form.append('file[]', this.fileListPic[i].raw);
                }
                form.append('id',this.fileModel.id);

                updateShowPic(form).then(res=>{
                  if (res.code === '0') {
                    this.$message({
                      message: '修改成功',
                      type: 'success',
                    });
                  }else {
                    this.$message({
                      type: "error",
                      message: res.msg
                    })
                  }
                  this.dialogVisible =false;
                  this.getTableList();
                });

              }else{

                updateCategory(this.fileModel).then(res => {
                  if (res.code === '0') {
                    this.$message({
                      message: '修改成功',
                      type: 'success',
                    });
                  }else {
                    this.$message({
                      type: "error",
                      message: res.msg
                    })
                  }
                  this.dialogVisible =false;
                  this.getTableList();
                });

              }

            }else {
              const form = new FormData();
              for (let i = 0; i < this.fileList.length; i++) {
                form.append('file[]', this.fileList[i].raw);
              }

              form.append('category',this.fileModel.category);
              uploadFile(form).then(response=>{
                if (response.code === '0') {
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },
  }
}
</script>
